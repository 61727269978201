'use client'
import { useEffect, useMemo } from 'react'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import { isTMA, retrieveLaunchParams } from '@telegram-apps/sdk'
import { useRouter, usePathname } from 'next/navigation'

import { getJwtToken, setupSafeAreaInset } from '@/shares/appInit'
import QueryProvider from '@/shares/reactQuery'
import { useGlobalStore } from '@/stores/global'
import { localGet, localSet } from '@/shares/storage'
import { ActiveItem, activeList } from '@/constants'
import { setupColor } from '@/shares/utils/telegram'

const Providers = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter()
  const pathname = usePathname()
  const { jwtOverride, appName } = useGlobalStore()
  console.log('pathname:', pathname)

  useEffect(() => {
    setupColor('020202')
    appInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const activeItem: ActiveItem = useMemo(() => {
    return activeList.find((item) => item.appName === appName) as ActiveItem
  }, [appName])

  useEffect(() => {
    const curItem: ActiveItem | undefined = activeList.find((item) => item.path === pathname)
    if (curItem) {
      useGlobalStore.setState({ appName: curItem.appName })
      localSet('appName', curItem.appName)
    }
  }, [pathname])

  async function appInit() {
    console.log('appInit')
    setupSafeAreaInset()
    localSet('test', 1)
    const isInApp = await isTMA()
    if (isInApp) {
      const launchParams = await retrieveLaunchParams()
      console.log('launchParams:', launchParams)
      console.log(launchParams?.initDataRaw)
      await useGlobalStore.setState({
        isInApp,
        launchParams,
      })
      const isMobile = ['android', 'ios'].includes(launchParams.platform)
      const isDebug = !!localGet('debug')

      if (isMobile || isDebug) {
        const jwt = localGet('jwt')
        if (jwt) {
          useGlobalStore.setState({ jwt, jwtOverride: false })
        } else {
          await getJwtToken()
        }
        if (pathname === '/mobile' || pathname === '/') {
          router.push(activeItem?.path || activeList[0].path)
        }
      } else {
        router.push('/mobile')
      }
    }
  }

  useEffect(() => {
    if (jwtOverride) {
      getJwtToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtOverride])

  return (
    <TonConnectUIProvider
      manifestUrl={`https://bk-web-app.bitkeep.zone/tonconnect-manifest.json`}
      walletsListConfiguration={{
        includeWallets: [
          {
            appName: 'tgbitgetTonWallet',
            name: 'Bitget Wallet',
            imageUrl:
              'https://raw.githubusercontent.com/bitkeepwallet/download/main/logo/png/bitget_wallet_logo_0_gas_fee.png',
            aboutUrl: 'https://web3.bitget.com?source=ton-cennect', // 添加归因source参数
            deepLink: 'bitkeep://',
            bridgeUrl: 'https://ton-connect-bridge.bgwapi.io/bridge',
            platforms: ['ios', 'android', 'chrome'],
            universalLink: 'https://bkcode.vip/ton-connect',
          },
        ],
      }}
    >
      <QueryProvider>{children}</QueryProvider>
    </TonConnectUIProvider>
  )
}

export default Providers
